import React, { useRef, useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import axios from "axios"
import Layout from "../components/layout"
import { Arrow, Checkmark } from "../components/icons"
import { mq } from "../styles/variables"

const fadeIn = keyframes`
  0% { opacity: 0; transform: translate(200px, -200px) rotate(135deg) }
  20% { opacity: 1 }
  100% { opacity: 1;  transform: translate(0, -0) rotate(135deg) }
`

export const rotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`

const Wrapper = styled.div`
  position: relative;
`

const Heading = styled.h1`
  max-width: 670px;
`

const SubHeading = styled.p`
  font-size: 32px;
  text-align: right;
  margin: 80px 0 120px;
  max-width: 670px;
  position: relative;

  > svg {
    width: 47px;
    height: 40px;
    transform: rotate(135deg);
    position: absolute;
    left: 49%;
    top: 120%;
    opacity: 0;
    animation: ${fadeIn} 400ms 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  @media ${mq.gte("m")} {
    font-size: 42px;
  }

  @media ${mq.gte("l")} {
    font-size: 60px;
    margin: 55px 0 155px;

    > svg {
      top: 100%;
      left: 25.5%;
    }
  }
`

const Form = styled.form`
  max-width: 670px;
  margin: 0 0 120px;
  position: relative;

  @media ${mq.gte("l")} {
    margin-bottom: 160px;
  }
`

const FormRow = styled.div`
  margin: 0 0 50px;
`

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  background: transparent;
  font-size: 22px;
  padding: 3px 0;
  margin: 0 0 0.12em;

  @media ${mq.gte("l")} {
    font-size: 32px;
    padding: 10px 0;
  }
`

const Attachment = styled.div`
  @media ${mq.gte("l")} {
    margin: 90px 0 0 0;
  }
`

const FileInputWrapper = styled.div`
  background: black;
  color: white;
  font-size: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 100px;
  padding: 19px 0 23px 25px;
  max-width: 380px;

  > svg {
    width: 17px;
    height: 14px;
    position: absolute;
    left: 26px;
    transform: rotate(-90deg);
  }

  @media ${mq.gte("l")} {
    font-size: 20px;
  }
`

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const Label = styled.label`
  font-size: 16px;
  display: inline-block;
  margin-right: 20px;

  @media ${mq.gte("l")} {
    font-size: 20px;
  }
`

const P = styled.p`
  font-size: 16px;
  margin-top: 14px;

  @media ${mq.gte("l")} {
    font-size: 20px;
  }
`

const Error = styled.span`
  font-size: 16px;
  display: inline-block;
  color: #a52150;

  @media ${mq.gte("l")} {
    font-size: 20px;
  }
`

const Press = styled.div`
  color: white;
  padding: 40px 16px 23px 26px;
  font-size: 16px;
  width: 240px;
  margin: 0 auto 120px;
  position: relative;

  > div {
    opacity: 0;
    transition: opacity 400ms 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: -1;
    transform: scale(1, 0);
    transform-origin: 50% 0;
    transition: transform 300ms 100ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.is-active {
    > div {
      opacity: 1;
    }

    &::after {
      transform: scale(1, 0.99);
    }
  }

  h3 {
    margin-bottom: 1em;
    padding-right: 20px;
  }

  a {
    text-decoration: underline;
  }

  @media ${mq.gte("l")} {
    position: absolute;
    right: 0;
    top: 90px;
    width: 275px;
    padding-bottom: 32px;
    font-size: 18px;

    h3 {
      font-size: 40px;
    }
  }
`

const Button = styled.button`
  background: black;
  color: white;
  font-size: 18px;
  border-radius: 100px;
  padding: 19px 36px 23px 36px;
  transition: background 200ms;
  border: 0;

  &.disabled {
    background: rgba(0, 0, 0, 0.2);
    cursor: default;
  }

  @media ${mq.gte("l")} {
    font-size: 20px;
  }
`

const PolicyCheckbox = styled.div`
  margin: 0 0 44px;
`

const FileName = styled.span`
  opacity: 0.5;
  display: inline-block;
  margin-right: 30px;
`

const RemoveButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  font-size: 16px;
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 200ms;

  &:hover {
    box-shadow: inset 0 -2px rgba(0, 0, 0, 1);
  }
`

const Checkbox = styled.div`
  position: relative;
  font-size: 16px;

  span {
    display: inline-block;
    padding-left: 40px;

    &::before {
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      border: 1px solid black;
      position: absolute;
      left: 0;
      top: -1px;
    }
  }

  input {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
    top: -1px;
    appearance: none;
    opacity: 0;
  }

  input:checked + span > svg {
    opacity: 1;
  }

  svg {
    width: 14px;
    height: 10px;
    position: absolute;
    left: 6px;
    top: 7px;
    opacity: 0;
    transition: opacity 200ms;
  }
`

const Loader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(244, 238, 229, 0.7);
  z-index: 10;
`

const Spinner = styled.div`
  width: 80px;
  height: 80px;
  border: 5px solid black;
  border-left: 5px solid transparent;
  animation: ${rotate} 1000ms linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
  border-radius: 50%;
  z-index: 10;
`

const Contact = ({
  location,
  data: {
    sanityContact: { description, title },
  },
}) => {
  const fileInput = useRef()
  const [formData, setFormData] = useState({
    file: null,
    agree: { value: false },
  })
  const [hasSent, setHasSent] = useState(false)
  const [sucess, setSuccess] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const press = useRef()

  const isDisabled = !(formData.file && formData.agree.value)

  const onChange = (e) => {
    const { target } = e

    setFormData({
      ...formData,
      [target.name]: {
        value: target.type === "checkbox" ? target.checked : target.value,
        error: false,
      },
    })
  }

  const onFileChange = (e) => {
    const { files } = e.target

    if (files.length) {
      setFormData({
        ...formData,
        file: files[0].name,
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!isDisabled) {
      setIsSending(true)

      const form = document.getElementById("form")
      const formData = new FormData(form)

      try {
        const response = await axios.post(form.action, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        setSuccess(true)

        console.log(response.data)
      } catch (error) {
        console.error(error.response.data)

        setSuccess(false)
      }

      setHasSent(true)
    }
  }

  const removeFile = (e) => {
    e.preventDefault()

    setFormData({
      ...formData,
      file: null,
    })

    try {
      fileInput.curent.value = ""

      if (fileInput.current.value) {
        fileInput.current.typ = "text"
        fileInput.current.type = "file"
      }
    } catch (e) {
      /* */
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            entry.target.classList.add("is-active")
            observer.unobserve(entry.target)
          }
        }
      },
      { rootMargin: "0px 0px -60px 0px" }
    )

    observer.observe(press.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Layout
      color="#F4EEE5"
      location={location}
      description={description}
      title="Contact us"
    >
      <Wrapper>
        <Heading className="reveal">{title}</Heading>
        <SubHeading className="reveal">
          Send us your pitch. <Arrow />
        </SubHeading>

        {!hasSent ? (
          <Form
            id="form"
            onSubmit={onSubmit}
            className="reveal"
            novalidate
            method="POST"
            action="/upload"
          >
            <Attachment>
              <FormRow hidden={formData.file}>
                <FileInputWrapper>
                  <Arrow /> Upload your pitch presentation
                  <FileInput
                    type="file"
                    name="pitch"
                    onChange={onFileChange}
                    ref={fileInput}
                    required
                    accept=".pdf,.ppt,.pptx"
                  />
                </FileInputWrapper>

                <P>
                  Allowed file types:&nbsp;&nbsp; .pdf,&nbsp;&nbsp;.ppt, or
                  .pptx
                </P>
              </FormRow>

              <FormRow hidden={!formData.file}>
                Attached file:
                <br />
                <FileName>{formData.file}</FileName>{" "}
                <RemoveButton onClick={removeFile}>Remove</RemoveButton>
              </FormRow>

              <input
                type="checkbox"
                name="phone"
                value="1"
                style={{ display: "none" }}
                tabIndex="-1"
                autoComplete="off"
              />
            </Attachment>

            <div hidden={!formData.file}>
              <PolicyCheckbox>
                <label>
                  <Checkbox>
                    <input
                      type="checkbox"
                      name="agree"
                      checked={formData.agree.value}
                      onChange={onChange}
                    />
                    <span>
                      <Checkmark /> I agree with Alliance Venture&apos;s{" "}
                      <a
                        href="/privacy-policy"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </Checkbox>
                </label>
              </PolicyCheckbox>
              <Button type="submit" className={isDisabled ? "disabled" : ""}>
                Submit
              </Button>

              <input
                type="checkbox"
                name="phone"
                value="1"
                style={{ display: "none" }}
                tabIndex="-1"
                autoComplete="off"
              />
            </div>

            {isSending && (
              <Loader>
                <Spinner />
              </Loader>
            )}
          </Form>
        ) : (
          <Form as="div">
            {sucess && (
              <p>
                We have received your pitch presentation and will contact you
                soon!
              </p>
            )}

            {!sucess && (
              <>
                <p>
                  Something went wrong. Please try again later or contact us at{" "}
                  <a href="mailto:hello@alliance.vc">hello@alliance.vc</a>.
                </p>

                {serverErrors &&
                  Array.isArray(serverErrors) &&
                  serverErrors.map((error) => <p>{error}</p>)}
              </>
            )}
          </Form>
        )}

        <Press ref={press}>
          <div>
            <h3>Press contact:</h3>
            <p>
              <a href="mailto:hello@alliance.vc">hello@alliance.vc</a>
            </p>
          </div>
        </Press>
      </Wrapper>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactPageQuery {
    sanityContact {
      description
      title
    }
  }
`
